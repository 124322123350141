import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelopeOpen } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import Obfuscate from "react-obfuscate";

import Layout from "../components/layout";
import SEO from "../components/seo";

function ContactPage() {
  return (
    <Layout>
      {() => (
        <>
          <SEO keywords={[]} title="Imprint" />
          <div className="relative text-left">
            <div className="absolute inset-0">
              <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-100"></div>
            </div>
            <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
              <div className="bg-gray-100 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-20 xl:pr-12">
                <div className="max-w-lg mx-auto">
                  <h2 className="text-2xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-9">
                    Imprint
                  </h2>
                  <p className="mt-3 text-lg leading-6 text-gray-600">
                    Minthesize e.U.
                    <br />
                    DI Julian Rubisch
                    <br />
                    EDV-Dienstleistungen
                  </p>
                  <dl className="mt-8 text-base leading-6 text-gray-600">
                    <div>
                      <dt className="sr-only">Postal address</dt>
                      <dd>
                        <p>Keilgasse 6/1</p>
                        <p>1030 Wien</p>
                        <p>Austria</p>
                      </dd>
                    </div>
                    <div className="mt-6">
                      <dt className="sr-only">Phone number</dt>
                      <dd className="flex items-center">
                        <FontAwesomeIcon icon={faPhone} />
                        <span className="ml-3">+43 664 407 55 11</span>
                      </dd>
                    </div>
                    <div className="mt-3">
                      <dt className="sr-only">Email</dt>
                      <dd className="flex items-center">
                        <FontAwesomeIcon icon={faEnvelopeOpen} />
                        <span className="ml-3">
                          <Obfuscate email="info@minthesize.com" />
                        </span>
                      </dd>
                    </div>
                    <div className="mt-3">
                      <dt className="sr-only">UID</dt>
                      <dd className="flex items-center">UID: ATU68948936</dd>
                    </div>
                    <div className="mt-3">
                      <dt className="sr-only">FN</dt>
                      <dd className="flex items-center">
                        FN: 423338g, Handelsgericht Wien
                      </dd>
                    </div>
                    <div className="mt-3">
                      <dt className="sr-only"></dt>
                      <dd className="flex items-center">
                        WKO Wien,{" "}
                        <a
                          className="ml-2 text-magenta-900"
                          href="https://www.wko.at/branchen/w/information-consulting/unternehmensberatung-buchhaltung-informationstechnologie/Willkommen_in_der_Fachgruppe_UBIT_Wien.html"
                        >
                          Fachgruppe UBIT
                        </a>
                      </dd>
                    </div>
                    <div className="mt-3">
                      <dt className="sr-only">FN</dt>
                      <dd className="flex items-center">
                        Anwendbare Rechtsvorschriften:{" "}
                        <a
                          className="ml-2 text-magenta-900"
                          href="https://www.ris.bka.gv.at/"
                        >
                          Gewerbeordnung
                        </a>
                      </dd>
                    </div>
                    <div className="mt-3">
                      <dt className="sr-only">FN</dt>
                      <dd className="flex items-center">
                        Aufsichtsbehörde:{" "}
                        <a
                          className="ml-2 text-magenta-900"
                          href="https://www.wien.gv.at/mba/mba.html"
                        >
                          Magistratisches Bezirksamt Wien
                        </a>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
}

export default ContactPage;
